/* eslint-disable fp/no-mutation */
import React from 'react'
import i18next from 'i18next'
import {
  IQGWScheduleSupportWithSavingsMode,
  hasEncharge,
  hasEnpower,
  hasHemsDevices,
  isDTSupportedSite,
  isEvseSite,
  isHemsSite,
  isIQGWScheduleSupportedSite,
  isNEM3Site,
  isPvAndCtWithEvseSite,
  nem3PVBatteryIQEVSESite,
  nem3PVBatteryIQSystemControllerSite,
  showSavingsMode,
} from 'lib/utils/siteDetails'
import {PROFILE_TYPE} from 'lib/utils/constants'
import {FEATURE_KEYS, isFeatureSupported} from 'lib/utils/featureKeys'

function getSelfConsumptionProfileDescription() {
  let desc = ''
  if (isIQGWScheduleSupportedSite()) {
    if (isDTSupportedSite() && hasHemsDevices()) {
      desc = i18next.t('bp318')
    } else if (nem3PVBatteryIQEVSESite()) {
      desc = i18next.t('bp430')
    } else {
      desc = i18next.t('bp205')
    }
  } else if ((isHemsSite() && hasHemsDevices()) || isEvseSite()) {
    desc = i18next.t('bp309')
  } else {
    desc = i18next.t('bp205')
  }
  return desc
}

function getSavingsProfileDescription() {
  let desc = ''
  if (IQGWScheduleSupportWithSavingsMode()) {
    desc = i18next.t('bp437') // Maximizes savings based on electricity rate, consumption pattern and expected solar production
  } else if ((isHemsSite() && hasHemsDevices() && !isIQGWScheduleSupportedSite()) || isEvseSite()) {
    desc = i18next.t('bp319') // Minimizes electricity bill. Smart charging for EV
  } else {
    desc = i18next.t('bp206') // Minimizes electricity bill
  }
  return desc
}

function getFullBackupProfileDescription() {
  let desc = ''
  if (isIQGWScheduleSupportedSite() && nem3PVBatteryIQSystemControllerSite()) {
    desc = i18next.t('bp207')
  } else if ((isHemsSite() && hasHemsDevices()) || isEvseSite()) {
    desc = i18next.t('bp311')
  } else {
    desc = i18next.t('bp207')
  }
  return desc
}

// This Profile Details
export function getThisProfileDetails(type) {
  switch (type) {
    case PROFILE_TYPE.SELF_CONSUMPTION: {
      return {
        title: i18next.t('bp22'),
        description: getSelfConsumptionProfileDescription(),
        showProfile: hasEncharge() || isPvAndCtWithEvseSite() || isDTSupportedSite(),
      }
    }
    case PROFILE_TYPE.COST_SAVINGS: {
      return {
        title: IQGWScheduleSupportWithSavingsMode() ? i18next.t('bp436') : i18next.t('bp30'),
        description: getSavingsProfileDescription(),
        showProfile: showSavingsMode(),
      }
    }
    case PROFILE_TYPE.BACKUP_ONLY:
      return {
        title: i18next.t('bp16'),
        description: getFullBackupProfileDescription(),
        showProfile: hasEnpower(),
      }
    default:
      return false
  }
}

export function renderSavingAIPowerBeta(profile) {
  if (
    (isNEM3Site() || isDTSupportedSite()) &&
    isFeatureSupported(FEATURE_KEYS.SAVINGS_AI_POWERED_BETA) &&
    profile === PROFILE_TYPE.COST_SAVINGS
  ) {
    return <sup className="com__ad--title-prefix"> {i18next.t('bp434')} </sup>
  }
  return null
}
